import {PinturaTargetSize} from '@pqina/pintura'

export const TREE_GALLERY_PREVIEW_IMAGE_TARGET_SIZES: PinturaTargetSize = {
  width: 800,
  height: 800,
  fit: 'contain',
  upscale: false,
}

export const AVATAR_IMAGE_TARGET_SIZES: PinturaTargetSize = {
  width: 600,
  height: 600,
  fit: 'contain',
  upscale: false,
}

export const PNG_IMAGE_TARGET_SIZES: PinturaTargetSize = {
  width: 1000,
  height: 1000,
  fit: 'contain',
  upscale: false,
}

export const GALLERY_IMAGE_TARGET_SIZES: PinturaTargetSize = {
  width: 2400,
  height: 2400,
  fit: 'contain',
  upscale: false,
}

export const FIELD_IMAGE_TARGET_SIZES: PinturaTargetSize = {
  width: 100,
  height: 100,
  fit: 'cover',
  upscale: false,
}
