import React, {useMemo} from 'react'
import styled from 'styled-components'
import calcTree from 'dehimer-relatives-tree'

import {getNodeStyle} from '../utils'

import useMinScale from '../hooks/useMinScale'
import useHeirChainFromRoot from '@components/organisms/Tree/hooks/useHeirChainFromRoot'
import useFirstNodeId from '@components/organisms/Tree/hooks/useFirstNodeId'
import useTreeStyles from '@hooks/useTreeStyles'

import PinchZoomPan from './PinchZoomPan'
import Connector from './Connector'
import Node from './Node'

import {NODE_HEIGHT, NODE_WIDTH} from '../constants'

const TreeCanvas = ({
  treeId,
  nodes,
  personsMap,
  editMode,
  subTreeOwner,
  readonly,
  initialized = true,
}) => {
  // console.log('### TreeCanvas', treeId, nodes, personsMap)
  const firstNodeId = useFirstNodeId(nodes)
  // console.log('### firstNodeId', firstNodeId)

  const treeData = useMemo(() => {
    // console.log('### treeData.useMemo')
    return calcTree([...nodes], {
      rootId: firstNodeId,
    })
  }, [nodes, firstNodeId])

  const heirChainNodes = useHeirChainFromRoot(treeId)

  const {minScale, wrapperRef} = useMinScale({
    treeData,
  })

  const {lineShadowWidth} = useTreeStyles(treeId)

  console.log('### minScale', minScale)

  return (
    <Wrapper ref={wrapperRef} $visible={initialized}>
      {typeof minScale !== 'undefined' ? (
        <StyledPinchZoomPan min={minScale / 3} initialZoom={minScale || 0} max={2} captureWheel>
          <TreeWrapper
            style={{
              width: treeData.canvas.width * NODE_WIDTH,
              height: treeData.canvas.height * NODE_HEIGHT,
            }}
          >
            {lineShadowWidth > 0 &&
              treeData.connectors.map((connector, idx) => {
                const payload = connector[4] || {}
                const {nodes} = payload
                const node = nodes?.find(({virtual}) => !virtual) || payload.node
                const person = node && personsMap[node.id]

                return (
                  <Connector
                    key={idx}
                    treeId={treeId}
                    connector={connector}
                    node={node}
                    person={person}
                    width={NODE_WIDTH}
                    height={NODE_HEIGHT}
                    shadow
                  />
                )
              })}
            {treeData.connectors.map((connector, idx) => {
              const payload = connector[4] || {}
              const {nodes} = payload
              const node = nodes?.find(({virtual}) => !virtual) || payload.node
              const person = node && personsMap[node.id]

              const inHeirChain = heirChainNodes.includes(node?.id)

              return (
                <Connector
                  key={idx}
                  treeId={treeId}
                  connector={connector}
                  node={node}
                  person={person}
                  width={NODE_WIDTH}
                  height={NODE_HEIGHT}
                  inHeirChain={inHeirChain}
                  heirChainNodes={heirChainNodes}
                />
              )
            })}
            {treeData.nodes.map((node) => (
              <Node
                key={node.id}
                treeId={treeId}
                node={node}
                root={node.id === firstNodeId}
                person={personsMap[node.id]}
                inHeirChain={heirChainNodes.includes(node.id)}
                owner={node.ownerId ? personsMap[node.ownerId] : undefined}
                style={getNodeStyle(node)}
                editMode={editMode}
                subTreeOwner={subTreeOwner}
                readonly={readonly}
              />
            ))}
          </TreeWrapper>
        </StyledPinchZoomPan>
      ) : null}
    </Wrapper>
  )
}

export default React.memo(TreeCanvas)

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const StyledPinchZoomPan = styled(PinchZoomPan)`
  width: 100%;
  height: 100%;
`

const TreeWrapper = styled.div`
  position: relative;
`
