import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useIntl} from 'react-intl'
import * as linkify from 'linkifyjs'
import linkifyHtml from 'linkify-html'

import {TreePublishType} from '@api/models'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

type ViewTreeAuthorDrawerContentProps = {tree: TreePublishType}
const ViewTreeAuthorDrawerContent = ({tree}: ViewTreeAuthorDrawerContentProps) => {
  console.log('### ViewTreeAuthorDrawerContent.tree', tree)
  const intl = useIntl()
  const treeAvatarUrl = useHostBasedUrl(tree?.author.avatar?.url)

  const linkedDescription = useMemo(
    () =>
      tree?.author?.description
        ? linkifyHtml(tree.author.description, {
            defaultProtocol: 'https',
            target: {url: '_blank'},
          })
        : null,
    [tree]
  )

  return (
    <div style={{overflowX: 'hidden'}} className='card-body hover-scroll-overlay-y h-400px pt-5'>
      <div className='d-flex flex-column  mb-9 px-9'>
        <div className='symbol symbol-150px mb-4 text-center'>
          <img
            src={treeAvatarUrl ? treeAvatarUrl : 'https://my-engine.ru/modules/users/avatar.png'}
            className=''
            alt=''
          />
        </div>

        <div className='text-center'>
          <span className='text-gray-800 fw-bold text-hover-primary fs-1'>
            {tree?.author.first_name} {tree?.author.last_name}
          </span>
        </div>

        {linkedDescription ? (
          <Description
            className='lead ql-editor'
            dangerouslySetInnerHTML={{__html: linkedDescription}}
          />
        ) : null}

        <div className='card border-0 shadow-none rounded-0 w-100'>
          <div className='card-header p-0 mb-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>
                {intl.formatMessage({id: 'ADD.FIELD.SOCIAL_NETWORKS_AND_LINKS'})}
              </span>
            </h3>
          </div>

          {tree?.author.params?.socialNetworks?.map(([networkName, networkLink], index) => {
            const highlightedLink = linkifyHtml(networkLink, {
              defaultProtocol: 'https',
              target: {url: '_blank'},
            })

            return (
              <div key={index} className='row mb-7'>
                <div className='col-lg-4 fw-semibold text-muted fs-6'>{networkName}</div>
                <div
                  className='col-lg-8 fw-semibold text-gray-800 fs-6'
                  dangerouslySetInnerHTML={{
                    __html: highlightedLink,
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ViewTreeAuthorDrawerContent)

const Description = styled.div`
  & blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
`
