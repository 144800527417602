import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import SVG from 'react-inlinesvg'
import {useIntl} from 'react-intl'

import {useLayout} from '@_metronic/layout/core'

import {useViewTreeAuthorDrawerStore} from '@components/molecules/ViewTreeAuthorDrawer/ViewTreeAuthorDrawer'

import {TreeModel} from '@api/models'

import {KTIcon, toAbsoluteUrl} from '@_metronic/helpers'

type ToolbarWrapperProps = {
  className?: string
  tree: TreeModel
  editMode?: boolean
  readonly?: boolean
  refToken?: string
  children?: React.ReactNode
  onEditModeChange?: (value: boolean) => void
}
const ToolbarWrapper = ({
  className,
  tree,
  editMode,
  readonly,
  children,
  onEditModeChange,
}: ToolbarWrapperProps) => {
  const intl = useIntl()
  const [treeAvatar, setTreeAvatar] = useState<{url: string} | null>(null)
  useEffect(() => {
    if (tree) {
      // console.log('### tree', tree)
      // @ts-ignore
      setTreeAvatar(tree.avatar)
    }
  }, [tree])
  const {config, classes} = useLayout()

  const {show} = useViewTreeAuthorDrawerStore()
  const handleOpenTreeCard = () => {
    if (tree?.publish) {
      show(tree.publish)
    }
  }

  if (!config.app?.toolbar?.display) {
    return null
  }

  return (
    <div
      className={clsx(
        'app-toolbar',
        classes.toolbar.join(' '),
        config?.app?.toolbar?.class,
        className
      )}
    >
      <Container
        id='kt_app_toolbar_container'
        className={clsx(
          'app-container',
          classes.toolbarContainer.join(' '),
          config.app?.toolbar?.containerClass,
          config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
          {
            'container-fluid': config.app?.toolbar?.container === 'fluid',
            'container-xxl': config.app?.toolbar?.container === 'fixed',
          }
        )}
      >
        <div className='cursor-pointer symbol symbol-35px me-4' onClick={handleOpenTreeCard}>
          <img
            src={
              tree?.publish?.author.avatar?.url ?? 'https://my-engine.ru/modules/users/avatar.png'
            }
            className='rounded-3'
            alt='user'
          />
        </div>

        <div
          data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className='page-title d-flex flex-wrap flex-fill me-3 flex-column justify-content-center'
        >
          <h1
            className={clsx(
              'page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center d-sm-flex',
              !readonly && 'd-none'
            )}
          >
            {tree?.publish?.author.first_name} {tree?.publish?.author.last_name}
          </h1>
          <Author>{intl.formatMessage({id: 'AUTHOR'})}</Author>
        </div>

        {children}

        {onEditModeChange ? (
          <>
            <div className='form-check form-switch form-check-custom form-check-solid'>
              <label className='form-check-label me-2'>
                <KTIcon iconName='pencil' className='fs-3' />
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                checked={editMode}
                onChange={() => onEditModeChange?.(!editMode)}
              />
            </div>
            <button
              id={'kt_tree_customization_toggle'}
              className='btn btn-sm btn-icon  btn-secondary ms-2'
            >
              <i className='ki-duotone ki-setting-4 fs-4 me-1'></i>
            </button>
            <button
              id={'kt_tree_publish_toggle'}
              className='btn btn-sm btn-icon btn-secondary ms-2'
            >
              <ShareIcon src={toAbsoluteUrl('/media/icons/duotune/arrows/arr078.svg')} />
            </button>
          </>
        ) : null}
      </Container>
    </div>
  )
}

export {ToolbarWrapper}

const Container = styled.div`
  padding-left: 0 !important;
  padding-right: 0 !important;
`

const ShareIcon = styled(SVG)`
  height: 20px;
  color: #78829d;
`

const Author = styled.div`
  color: #aaaaaa;
  font-size: 12px;
  font-weight: 400;

  width: 100%;
  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;

  margin-top: 3px;
`
