import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const AUTH_COOKIE_TOKEN_KEY = 'Auth-Token'

const getAuthOld = (): AuthModel | undefined => {
  console.log('### getAuthOld')
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    console.log('### lsauth', auth)
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const getAuth = (): AuthModel | undefined => {
  try {
    const TOKEN = document.cookie
      .split('; ')
      .find((row) => row.startsWith(AUTH_COOKIE_TOKEN_KEY))
      ?.split('=')[1]

    if (!TOKEN) {
      return getAuthOld()
    }

    const auth: AuthModel = {TOKEN} as AuthModel
    if (auth) {
      return auth
    }

    return getAuthOld()
  } catch (error) {
    console.error('AUTH COOKIE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  try {
    const currentDomain = window.location.hostname
    const parentDomain = currentDomain.split('.').slice(-2).join('.')
    document.cookie = `${AUTH_COOKIE_TOKEN_KEY}=${auth.TOKEN}; domain=.${parentDomain}; path=/; secure; samesite=none`
  } catch (error) {
    console.error('AUTH COOKIE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  try {
    const currentDomain = window.location.hostname

    document.cookie = `${AUTH_COOKIE_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${currentDomain}; path=/`

    // Удаляем куку для родительского домена (если текущий домен — поддомен)
    if (currentDomain.includes('.')) {
      const parentDomain = currentDomain.split('.').slice(-2).join('.')
      document.cookie = `${AUTH_COOKIE_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.${parentDomain}; path=/`
    }

    // Проверяем, удалилась ли кука
    console.log(document.cookie) // Кука должна исчезнуть

    // Удаляем старый вариант тоже
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH COOKIE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  console.log('### setupAxios')
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.TOKEN) {
        config.headers.Authorization = `Bearer ${auth.TOKEN}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, getAuthOld, setAuth, removeAuth}
