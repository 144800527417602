import {create} from 'zustand'

import {createPerson, deletePerson, editPersonAx, getPersons} from '@api/requests'
import {AvatarModel, PersonModel} from '@api/models'

import {RelationsEnum} from '@components/organisms/Tree/constants'
import {RelationType} from '@app/types'
import {UserModel} from '@modules/auth'

type PersonsStore = {
  updatedPersons: {[personId: number]: PersonModel}
  setActivePersonId: (personId: number | null) => void
  activePersonId: null | number
  createPerson: (
    newPersonValues: object,
    avatar: AvatarModel | null,
    extraOptions?: {currentUser?: UserModel}
  ) => Promise<void>
  editPerson: (
    personId: number | null | undefined,
    newPersonValues: object,
    extraOptions?: {currentUser?: UserModel}
  ) => Promise<void>
  removePerson: (personId: number | null | undefined) => Promise<void>
  getAllPersons: () => Promise<void>
  persons: PersonModel[]
  updatePersons: (
    persons: PersonModel[],
    withReset?: boolean,
    callback?: (persons?: PersonModel[]) => void
  ) => void
  addPartnerToPerson: (
    personId: number,
    relationPersonId: number,
    relationType: RelationsEnum,
    onError?: (value: string) => void
  ) => void
  deletePartnerOfPerson: (
    personId: number,
    relationPersonId: number,
    onError?: (value: string) => void
  ) => void
  addRelationToPerson: (
    payload: {personId: number; relationId: number; relationType: RelationType},
    onError?: (value: string) => void
  ) => void
  deleteRelationOfPerson: (
    payload: {personId: number; relationId: number; relationType: RelationType},
    onError?: (value: string) => void
  ) => void
}

const relationFields = {
  [RelationType.parent]: 'parents',
  [RelationType.pet]: 'pets',
  [RelationType.friend]: 'friends',
}

export const usePersonsStore = create<PersonsStore>((set, get) => ({
  activePersonId: null,
  persons: [],
  updatedPersons: {},

  updatePersons: (persons: PersonModel[], withReset = false, callback) => {
    // console.log('### updatePersons')
    set((state) => {
      const personIdMap = persons.map(({id}) => id)
      const updatedPersons = [
        ...(withReset ? [] : state.persons.filter(({id}) => !personIdMap.includes(id))),
        ...persons,
      ]

      callback?.(updatedPersons)

      return {
        ...state,
        persons: updatedPersons,
      }
    })
  },
  removePerson: async (personId: number | null | undefined) => {
    if (!personId) return
    await deletePerson(personId).then(() => {
      set((state) => {
        const persons = state.persons.filter((person) => person.id !== personId)

        return {
          ...state,
          persons: [...persons],
        }
      })
    })
  },
  createPerson: async (
    newPersonValues,
    avatar,
    extraOptions?: {currentUser?: UserModel; callback?: (person: PersonModel) => void}
  ) => {
    // @ts-ignore
    const isPremiumUser = !extraOptions?.currentUser || extraOptions?.currentUser.is_premium
    // @ts-ignore
    const newAvatarUrl = avatar?.url
    // @ts-ignore
    const newAvatarIsNotPresented = !newPersonValues?.params?.avatars?.find(
      // @ts-ignore
      ({url}) => url === avatar?.url
    )
    // console.log('### newAvatarIsNotPresented', newAvatarIsNotPresented)
    if (isPremiumUser && newAvatarUrl && newAvatarIsNotPresented) {
      // @ts-ignore
      newPersonValues.params.avatars = [
        // @ts-ignore
        avatar,
        // @ts-ignore
        ...(newPersonValues.params.avatars || []),
      ]
    }

    const response = await createPerson(newPersonValues)
    const createdPerson = response.data
    console.log('### createdPerson', createdPerson)

    set((state) => ({...state, persons: [{...createdPerson, avatar}, ...state.persons]}))

    extraOptions?.callback?.(createdPerson)
  },
  editPerson: async (
    personId: number | null | undefined,
    newPersonValues: object,
    extraOptions?: {currentUser?: UserModel}
  ) => {
    console.log('### editPerson', personId, newPersonValues)
    if (!personId) return

    const persons = get().persons || []
    const personIndex = persons.findIndex((person) => person.id === personId)

    if (personIndex === -1) return Promise.reject()

    const currentPerson = persons[personIndex]

    const updatedPerson = {...currentPerson, ...newPersonValues}
    console.log('### updatedPerson', updatedPerson)
    updatedPerson.is_subtree = updatedPerson.is_subtree || 0

    // @ts-ignore
    const isPremiumUser = !extraOptions?.currentUser || extraOptions?.currentUser.is_premium
    console.log('#### isPremiumUser', isPremiumUser)
    // @ts-ignore
    const newAvatarUrl = newPersonValues?.avatar?.url
    console.log('#### newAvatarUrl', newAvatarUrl)

    const newAvatarIsNotPresented = !updatedPerson?.params?.avatars?.find(
      // @ts-ignore
      ({url}) => url === newPersonValues?.avatar?.url
    )

    if (isPremiumUser && newAvatarUrl && newAvatarIsNotPresented) {
      updatedPerson.params.avatars = [
        // @ts-ignore
        updatedPerson?.avatar,
        ...(updatedPerson.params.avatars || []),
      ]
      console.log(updatedPerson.params.avatars)
    }

    const response = await editPersonAx(personId, updatedPerson)
    console.log('### editPersonAx.response', response)
    set((state) => {
      const personsClone = [...state.persons]
      personsClone[personIndex] = response.data

      return {
        ...state,
        persons: personsClone,
      }
    })
  },
  addPartnerToPerson: (personId, relationPersonId, relationType, onError) => {
    console.log('### addPartnerToPerson', personId, relationPersonId)
    const persons = get().persons || []
    console.log('### persons', persons)
    const currentPersonIndex = persons.findIndex((p) => p.id === personId)
    console.log('### currentPersonIndex', currentPersonIndex)
    const relationPersonIndex = persons?.findIndex((p) => p.id === relationPersonId)
    console.log('### relationPersonIndex', relationPersonIndex)

    if (currentPersonIndex !== -1 && relationPersonIndex !== -1) {
      const currentPerson = persons[currentPersonIndex]
      console.log('### currentPerson', currentPerson)
      const foundCurrentPersonRelationIndex = (currentPerson.partners || []).findIndex(
        ({partner_id}) => partner_id === relationPersonId
      )

      if (foundCurrentPersonRelationIndex !== -1) {
        persons[currentPersonIndex].partners[foundCurrentPersonRelationIndex].status = relationType
      } else {
        // @ts-ignore
        persons[currentPersonIndex].partners = [
          {partner_id: relationPersonId, status: relationType},
          ...(currentPerson.partners || []).filter(
            ({partner_id, status}) => status && partner_id !== relationPersonId
          ),
        ]
      }

      const relationPerson = persons[relationPersonIndex]
      const foundRelationPersonRelationIndex = (relationPerson.partners || []).findIndex(
        ({partner_id}) => partner_id === personId
      )

      if (foundRelationPersonRelationIndex !== -1) {
        persons[relationPersonIndex].partners[foundRelationPersonRelationIndex].status =
          relationType
      } else {
        // @ts-ignore
        persons[relationPersonIndex].partners = [
          {partner_id: personId, status: relationType},
          ...(relationPerson.partners || []).filter(({partner_id}) => partner_id !== personId),
        ]
      }

      editPersonAx(personId, persons[currentPersonIndex])
        .then((response) => {
          console.log('### editPersonAx.response', response)
          set((state) => ({...state, persons: [...persons]}))
        })
        .catch((error) => {
          console.log('### error', error)
          onError?.(error?.message)
        })
    }
  },
  deletePartnerOfPerson: (personId, relationPersonId, onError) => {
    console.log('### deletePartnerOfPerson', personId, relationPersonId)
    const persons = get().persons || []
    const currentPersonIndex = persons.findIndex((p) => p.id === personId)
    console.log('### currentPersonIndex', currentPersonIndex)
    const relationPersonIndex = persons?.findIndex((p) => p.id === relationPersonId)
    console.log('### relationPersonIndex', relationPersonIndex)

    if (currentPersonIndex !== -1 && relationPersonIndex !== -1) {
      const currentPerson = persons[currentPersonIndex]
      const relationPerson = persons[relationPersonIndex]

      // @ts-ignore
      persons[currentPersonIndex].partners = [
        ...(currentPerson.partners || []).filter(({partner_id}) => partner_id !== relationPersonId),
      ]

      // @ts-ignore
      persons[relationPersonIndex].partners = [
        ...(relationPerson.partners || []).filter(({partner_id}) => partner_id !== personId),
      ]

      // @ts-ignore
      editPersonAx(personId, persons[currentPersonIndex])
        .then((response) => {
          set((state) => ({...state, persons: [...persons]}))
        })
        .catch((error) => {
          console.log('### error', error)
          onError?.(error?.message)
        })
    }
  },
  addRelationToPerson: ({personId, relationId, relationType}, onError) => {
    console.log('### addRelationToPerson', personId, relationId, relationType)
    const persons = get().persons || []
    const currentPersonIndex = persons.findIndex((p) => p.id === personId)
    const relationPersonIndex = persons.findIndex((p) => p.id === relationId)

    if (currentPersonIndex !== -1 && relationPersonIndex !== -1) {
      const currentPersonClone = {...persons[currentPersonIndex]}

      const fieldName = relationFields[relationType] || 'pets'

      // @ts-ignore
      currentPersonClone[fieldName] = [
        ...(currentPersonClone[fieldName] || []).filter((id) => id !== relationId),
        relationId,
      ]

      // console.log('### currentPersonClone', currentPersonClone)
      editPersonAx(personId, currentPersonClone)
        .then((response) => {
          console.log('### response', response)
          const updatedPerson = response.data
          set((state) => {
            const personsClone = [...state.persons]

            personsClone[currentPersonIndex] = updatedPerson
            console.log('### personsClone', personsClone)
            return {
              ...state,
              persons: personsClone,
              updatedPersons: {...state.updatedPersons, [personId]: updatedPerson},
            }
          })
        })
        .catch((error) => {
          console.log('### error', error)
          onError?.(error?.message)
        })
    }
  },
  deleteRelationOfPerson: ({personId, relationId, relationType}, onError) => {
    const persons = get().persons || []
    const currentPersonIndex = persons.findIndex((p) => p.id === personId)
    const relationPersonIndex = persons?.findIndex((p) => p.id === relationId)

    if (currentPersonIndex !== -1 && relationPersonIndex !== -1) {
      const currentPerson = persons[currentPersonIndex]
      const fieldName = relationFields[relationType] || 'pets'

      // @ts-ignore
      persons[currentPersonIndex][fieldName] = [
        ...(currentPerson[fieldName] || []).filter((id) => id !== relationId),
      ]

      editPersonAx(personId, persons[currentPersonIndex])
        .then((response) => {
          set((state) => ({...state, persons: [...persons]}))
        })
        .catch((error) => {
          console.log('### error', error)
          onError?.(error?.message)
        })
    }
  },
  getAllPersons: async () => {
    console.log('### getAllPersons')
    let response = await getPersons()

    set((state) => {
      return {
        ...state,
        persons: response.data,
      }
    })
  },
  setActivePersonId: (personId) => {
    set((state) => ({...state, activePersonId: personId}))
  },
}))
