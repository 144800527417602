import React, {useMemo} from 'react'
import styled from 'styled-components'

import {useCultsStore} from '@pages/CultsPage/store'
import {useCultDrawerStore} from '@_metronic/partials/layout/cult-drawer/CultDrawer'

import useTreeStyles from '@hooks/useTreeStyles'

import {NODE_AVATAR_SIZE, NODE_HEIGHT} from '@components/organisms/Tree/constants'
import {TreeModel} from '@api/models'

const BUTTON_SIZE = 30

type CultProps = {size?: number; lookId: number; treeId: TreeModel['id']}
const Cult = ({size = NODE_AVATAR_SIZE, lookId, treeId}: CultProps) => {
  // console.log('### size', size)

  const {cults} = useCultsStore()
  const {show} = useCultDrawerStore()

  const koef = size / NODE_AVATAR_SIZE
  // console.log('### koef, size / NODE_HEIGHT', koef, size, NODE_HEIGHT)

  const cult = useMemo(() => {
    // console.log('### lookId', lookId, cults)
    if (lookId) {
      return cults?.find(({id}) => id === lookId)
    }
  }, [cults, lookId])

  const {
    cultBorderColor,
    cultBorderWidth = 2,
    cultBorderRadius,
    cultBorderType,
    avatarShadowColor,
    avatarShadowDisplaying,
  } = useTreeStyles(treeId)
  const cultBorderWidthParsed = parseInt(`${cultBorderWidth}`)
  const cultBorderRadiusParsed = parseInt(`${cultBorderRadius}`)

  if (!cult) return null

  const handleClick = (event) => {
    console.log('### handleClick')
    event.stopPropagation()
    show(cult.id)
  }

  return (
    <Wrapper
      $koef={koef}
      $avatarBorderColor={cultBorderColor}
      $avatarBorderWidth={cultBorderWidthParsed}
      $avatarBorderRadius={cultBorderRadiusParsed}
      $avatarBorderType={cultBorderType as string}
      $avatarShadowColor={avatarShadowColor}
      $avatarShadowDisplaying={avatarShadowDisplaying}
      onClick={handleClick}
    >
      {cult?.file?.url ? <Img src={cult?.file?.url} alt='' /> : null}
    </Wrapper>
  )
}

export default React.memo(Cult)

const Wrapper = styled.div<{
  $koef: number

  $avatarBorderColor?: string | number
  $avatarBorderWidth: number
  $avatarBorderRadius: number
  $avatarBorderType: string
  $avatarShadowDisplaying?: string | number
  $avatarShadowColor?: string | number
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({$koef}) => BUTTON_SIZE * $koef}px;
  height: ${({$koef}) => BUTTON_SIZE * $koef}px;

  position: absolute;

  bottom: -${({$avatarBorderWidth = 0}) => BUTTON_SIZE / 2 - 15 + $avatarBorderWidth / 2}px;
  right: -${({$avatarBorderWidth = 0}) => BUTTON_SIZE / 2 - 7}px;

  border: ${({$avatarBorderType}) => $avatarBorderType}
    ${({$avatarBorderWidth}) => $avatarBorderWidth}px
    ${({$avatarBorderColor}) => $avatarBorderColor};
  border-radius: ${({$avatarBorderRadius}) => $avatarBorderRadius}%;

  box-shadow: ${({$avatarShadowDisplaying, $avatarShadowColor}) =>
    $avatarShadowDisplaying === 'true'
      ? `2px 2px 4px 0 ${$avatarShadowColor || 'rgba(0, 0, 0, 0.2)'}`
      : 'none'};

  box-sizing: border-box;

  overflow: hidden;

  cursor: pointer;
`

const Img = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`
