import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import styled, {css} from 'styled-components'

import {toAbsoluteUrl} from '@_metronic/helpers/AssetHelpers'

import {useCultDrawerStore} from '@_metronic/partials/layout/cult-drawer/CultDrawer'
import {useCultsStore} from '@app/pages/CultsPage/store'

import DescriptionSkeleton from './components/DescriptionSkeleton'
import CultSkeleton from './components/CultSkeleton'
import ParamSkeleton from './components/ParamSkeleton'

import Catalogs from './components/Catalogs'

import {PersonModel} from '@app/api/models'
import {isTimestamp} from '@app/utils/datetime'

type PersonInfoProps = {
  personId: number
  person?: PersonModel | null
  loading?: boolean
  showHeader?: boolean
  simpleMode?: boolean
  rowsMode?: boolean
}
const PersonInfo = ({
  personId,
  person: receivedPerson,
  loading: receivedLoading = false,
  showHeader = true,
  rowsMode = false,
}: PersonInfoProps) => {
  const intl = useIntl()

  const [person, setPerson] = useState<PersonModel | null>(receivedPerson || null)

  const [fields, setFields] = useState<PersonModel['params']['fields']>([])
  const [genderPronoun, setGenderPronoun] = useState<string>()

  const {cults, loadCults} = useCultsStore()
  useEffect(() => {
    if (person) {
      console.log('### person.params?.fields', person.params?.fields)
      if (person.params?.fields) {
        setFields(person.params.fields)
      }
      setGenderPronoun(person.params?.genderPronoun)
      if (person?.params?.cult) {
        loadCults()
      }
    } else {
      setFields([])
    }
  }, [person])

  useEffect(() => {
    setPerson(receivedPerson || null)
  }, [receivedPerson])

  const [loading, setLoading] = useState(receivedLoading)
  // const regetPerson = useRegetPerson(personId, true)
  // useEffect(() => {
  //   console.log('### personId?', personId)
  //   if (personId) {
  //     console.log('### personId', personId)
  //     setLoading(true)
  //     regetPerson()
  //       .then((receivedPerson) => {
  //         console.log('### receivedPerson', receivedPerson)
  //         if (receivedPerson) {
  //           setPerson(receivedPerson)
  //         }
  //       })
  //       .finally(() => setLoading(false))
  //   }
  // }, [personId])

  const cult = useMemo(() => {
    if (person?.look_id && cults) return cults.find(({id}) => id === person?.look_id)
  }, [cults, person])

  const {show: showCult} = useCultDrawerStore()
  const handleCultClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    showCult(cult?.id)
  }

  if (!person) return null
  console.log('### person', person)

  return (
    <>
      {showHeader &&
      person &&
      (person.type !== null ||
        person.gender !== null ||
        person.other_gender ||
        person.species ||
        cult ||
        person.character_traits ||
        person.life_goals ||
        fields?.length ||
        genderPronoun ||
        person.description) ? (
        <div className='card border-0 shadow-none rounded-0 w-100'>
          <div className='card-header p-0 mb-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>
                {intl.formatMessage({id: 'INFORMATION'})}
              </span>
            </h3>
          </div>
        </div>
      ) : null}

      <Wrapper rowsMode={rowsMode}>
        {loading ? <ParamSkeleton /> : null}

        {person ? (
          <>
            <div className='row mb-7 mt-7'>
              <div
                className='col-lg-4 fw-semibold text-muted fs-6'
                style={rowsMode ? {textAlign: 'left'} : {}}
              >
                {intl.formatMessage({id: 'SIM.TYPE'})}
              </div>

              <div className='col-lg-8 fv-row fw-semibold text-gray-800 fs-6'>
                {person.type === 1
                  ? intl.formatMessage({id: 'SIM.TYPE.PET'})
                  : intl.formatMessage({id: 'SIM.TYPE.PERSON'})}
              </div>
            </div>

            {loading ? <ParamSkeleton /> : null}
            {person.gender !== 2 && person.gender !== null && (
              <div className='row mb-7'>
                <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                  {intl.formatMessage({id: 'SIM.GENDER'})}
                </div>

                <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>
                  {person.gender === 0
                    ? intl.formatMessage({id: 'SELECT.GENDER.MALE'})
                    : intl.formatMessage({id: 'SELECT.GENDER.FEMALE'})}
                </div>
              </div>
            )}
            {loading ? <ParamSkeleton /> : null}
            {!loading && person.gender === 2 && person.other_gender ? (
              <div className='row mb-7'>
                <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                  {intl.formatMessage({id: 'SIM.GENDER'})}
                </div>
                <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{person.other_gender}</div>
              </div>
            ) : null}
            {loading ? <ParamSkeleton /> : null}
            {!loading && person.gender === 2 && genderPronoun ? (
              <div className='row mb-7'>
                <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                  {intl.formatMessage({id: 'SELECT.GENDER.PRONOUNS'})}
                </div>
                <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{genderPronoun}</div>
              </div>
            ) : null}
            {!loading ? <Catalogs person={person} /> : null}
            {loading ? <CultSkeleton /> : null}
            {!loading && !!cult && (
              <div className='row mb-7'>
                <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                  {intl.formatMessage({id: 'SIDEBAR.MENU.CULT'})}
                </div>
                <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>
                  {cult.file?.url ? (
                    <CultImage
                      src={toAbsoluteUrl(cult.file.url)}
                      alt='Pic'
                      role='button'
                      onClick={handleCultClick}
                    />
                  ) : null}
                  <span
                    onClick={handleCultClick}
                    role='button'
                    className='fw-semibold text-gray-800 fs-6'
                  >
                    {cult.name}
                  </span>
                </div>
              </div>
            )}
            {loading ? <ParamSkeleton /> : null}
            {!loading &&
              fields?.map(([name, value, image], index) => (
                <div className='row mb-7' key={index}>
                  <div className='col-lg-4 fw-semibold text-muted fs-6'>{name}</div>
                  <div className='col-lg-8 d-flex'>
                    {image && !isTimestamp(image) && <CustomFieldImage src={image} alt={value} />}
                    <div className='fw-semibold text-gray-800 fs-6'>{value}</div>
                  </div>
                </div>
              ))}

            {loading ? <DescriptionSkeleton /> : null}
            {!loading && person.description ? (
              <Description
                className='lead ql-editor'
                dangerouslySetInnerHTML={{__html: person.description}}
              />
            ) : null}
          </>
        ) : null}
      </Wrapper>

      {rowsMode ? <BottomShadow>...</BottomShadow> : null}
    </>
  )
}

export default PersonInfo

const Wrapper = styled.div<{rowsMode?: boolean}>`
  ${({rowsMode}) =>
    rowsMode
      ? css`
          & * {
            text-align: left;
            padding-left: 0;
            margin-left: 0;
          }

          & div:last-child {
            padding-left: 0;
            margin-left: 0;

            font-size: inherit;
          }
        `
      : ''}
`

const BottomShadow = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 40px;
  font-size: 30px;

  overflow: hidden;

  background: linear-gradient(rgba(255, 255, 255, 0), #ffffff);
`

const Description = styled.div`
  & blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
`

const CultImage = styled.img`
  max-width: 20px;
  max-height: 20px;

  border-radius: 15px;

  padding: 0;
  margin: 0;

  margin-right: 8px;
`

const CustomFieldImage = styled.img`
  width: 20px;
  height: 20px;
  padding: 0;
  margin-right: 8px;
`
