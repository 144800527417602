import clsx from 'clsx'

import {useAuth} from '@modules/auth'

import {HeaderUserMenu, ThemeModeSwitcher, HeaderNotificationsMenu} from '../../../partials'
import ProButton from '@components/atoms/ProButton'
import useHostBasedUrl from '@hooks/useHostBasedUrl'
import InstallPWAButton from '@app/components/molecules/InstallPWAButton'
import useIsMobileDevice from '@app/hooks/useIsMobileDevice'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'

const Navbar = () => {
  const {currentUser} = useAuth()
  const isMobile = useIsMobileDevice()

  const avatarUrl = useHostBasedUrl(currentUser?.avatar?.url)

  return (
    <div className='app-navbar flex-shrink-0'>
      <ProButton />

      {!isMobile && (
        <div className={clsx('app-navbar-item', itemClass)}>
          <InstallPWAButton />
        </div>
      )}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={clsx('position-relative', btnClass)}
        >
          <i className='ki-duotone ki-notification fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
          {currentUser?.notifications?.length ? (
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
          ) : null}
        </div>
        <HeaderNotificationsMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img
            src={avatarUrl ? avatarUrl : 'https://my-engine.ru/modules/users/avatar.png'}
            alt=''
          />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}
