import * as Yup from 'yup'
import enMessages from '@_metronic/i18n/messages/en.json'
import ruMessages from '@_metronic/i18n/messages/ru.json'
import ukMessages from '@_metronic/i18n/messages/uk.json'
import frMessages from '@_metronic/i18n/messages/fr.json'
import plMessages from '@_metronic/i18n/messages/pl.json'

import {emailRegex} from '@app/constants/regex'

const allMessages = {
  en: enMessages,
  ru: ruMessages,
  uk: ukMessages,
  fr: frMessages,
  pl: plMessages,
}

const setLocale = (locale: string) => {
  Yup.setLocale({
    string: {
      email: allMessages[locale]['AUTH.VALIDATION.EMAIL_FORMAT'],
      min: allMessages[locale]['AUTH.VALIDATION.MIN_LENGTH_FIELD'],
      max: allMessages[locale]['AUTH.VALIDATION.MAX_LENGTH_FIELD'],
    },
  })
}

export const loginSchema = (locale: string) => {
  setLocale(locale)

  return Yup.object().shape({
    email: Yup.string()
      .email()
      .min(3)
      .max(50)
      .matches(emailRegex, allMessages[locale]['AUTH.VALIDATION.EMAIL_FORMAT'])
      .required(allMessages[locale]['AUTH.VALIDATION.EMAIL_REQUIRED']),
    password: Yup.string()
      .min(3)
      .max(50)
      .required(allMessages[locale]['AUTH.VALIDATION.PASSWORD_REQUIRED']),
  })
}
