import {create} from 'zustand'
import {AxiosResponse} from 'axios'

import {
  createNode,
  createTree,
  deleteNode,
  deleteTree,
  editTree,
  getTrees,
  updateNode,
} from '@api/requests'

import {TreeModel, Node} from '@api/models'

type Trees = {[key: TreeModel['id']]: TreeModel}

type TreesStore = {
  busy: boolean
  trees: Trees
  setActiveTreeId: (treeId: number) => void
  activeTreeId?: number
  addNewTree: (values: {
    name: string
    description: string
    avatar_id: number
    background_id: number
    params: object
  }) => Promise<void | AxiosResponse>
  deleteTree: (treeId: number) => Promise<any>
  updateTree: (
    treeId: number,
    payload: {
      background_id?: TreeModel['background_id']
      background?: TreeModel['background']
      publish?: TreeModel['publish'] | {status: string}
      params?: TreeModel['params']
    }
  ) => Promise<void | AxiosResponse>
  addTree: (tree: TreeModel) => void
  getAllTrees: () => Promise<void>
  assignPersonToNode: (treeId: number, nodeId: number, personId: number) => void
  addEmptyChildren: (treeId: number, parentId: number) => void
  deleteNode: (treeId: number, nodeId: number) => void
  addEmptyRootParent: (treeId: number, nodeId: number) => void
  deleteRootNode: (treeId: number, nodeId: number) => void
  setHeirNodeId: (treeId: TreeModel['id'], nodeId: Node['id']) => void
  findPersonIdByNodeId: (treeId: number, nodeId: number) => number | null
}

export const useTreesStore = create<TreesStore>()((set, get) => ({
  busy: false,
  activeTreeId: undefined,
  trees: {},
  addTree: (tree: TreeModel) => {
    set((state) => ({
      ...state,
      activeTreeId: tree.id,
      trees: {...state.trees, [tree.id]: tree},
    }))
  },
  getAllTrees: async () => {
    let response = await getTrees()
    set((state) => {
      const trees = response.data

      const receivedTrees = trees.reduce((result, nextValues) => {
        result[nextValues.id] = nextValues
        return result
      }, {} as {[key: TreeModel['id']]: TreeModel})

      const storedActiveTreeId = parseInt(localStorage.getItem('activeTreeId') || '')

      return {
        ...state,
        activeTreeId:
          storedActiveTreeId && receivedTrees[storedActiveTreeId]
            ? storedActiveTreeId
            : trees.length
            ? Object.values(receivedTrees)[0].id
            : undefined,
        trees: receivedTrees,
        treesArray: trees,
        busy: false,
      }
    })
  },
  updateTree: (treeId, payload) => {
    // console.log('### updateTree', treeId, payload)
    const trees = get().trees
    const tree = trees[treeId]

    if (!tree) return Promise.reject()

    const data = tree?.publish?.status === 'public' ? {publish: tree?.publish, ...payload} : payload

    // @ts-ignore
    if (data.avatar?.url && !data.params.avatars?.find(({url}) => url === data.avatar?.url)) {
      // @ts-ignore
      data.params = {...data.params, avatars: [data.avatar, ...(data.params?.avatars || [])]}
    }

    return editTree(treeId, data).then((response) => {
      set((state) => {
        const activeTree = state.trees[treeId]

        if (activeTree) {
          const treeCopy = {...activeTree, ...response.data}
          // console.log('### treeCopy', treeId, treeCopy)

          return {
            ...state,
            trees: {
              ...state.trees,
              [treeId]: treeCopy,
            },
          }
        } else {
          return state
        }
      })

      return response
    })
  },
  deleteTree: (treeId: number) => {
    return deleteTree(treeId).then((response) => {
      set((state) => {
        const trees = state.trees
        delete trees[treeId]
        const activeTreeId = Object.keys(trees)[0]
        localStorage.setItem('activeTreeId', `${activeTreeId}`)

        return {...state, trees, activeTreeId: parseInt(activeTreeId)}
      })
      return response
    })
  },
  addNewTree: (data) => {
    return createTree(data).then((response) => {
      const newTree = response.data
      const treeId = newTree.id
      localStorage.setItem('activeTreeId', `${treeId}`)
      // @ts-ignore
      set((state) => {
        return {
          ...state,
          activeTreeId: treeId,
          trees: {
            ...state.trees,
            [treeId]: newTree,
          },
        }
      })
    })
  },
  setActiveTreeId: (treeId) => {
    // console.log('### setActiveTreeId', treeId)
    localStorage.setItem('activeTreeId', `${treeId}`)
    set((state) => ({...state, activeTreeId: treeId}))
  },
  addEmptyChildren: (treeId: number, parentId: number) => {
    set((state) => ({...state, busy: true}))
    createNode(treeId, parentId)
      .then((response) => {
        const newId: number = response.data.id

        // @ts-ignore
        set((state) => {
          if (!state.activeTreeId) return state

          const {nodes, ...restActiveTreeData} = state.trees[state.activeTreeId]
          const indexOfParent = nodes.findIndex(({id}) => parentId === id)

          if (indexOfParent !== -1) {
            const newPerson = {
              id: newId,
              parent_id: parentId,
            }
            return {
              ...state,
              trees: {
                ...state.trees,
                [state.activeTreeId]: {...restActiveTreeData, nodes: [...nodes, newPerson]},
              },
            }
          }

          return {...state}
        })
      })
      .catch((error) => console.log('### createNode.error', error))
      .finally(() => set((state) => ({...state, busy: false})))
  },
  assignPersonToNode: (treeId: number, node_id: number, person_id: number) => {
    updateNode(treeId, node_id, undefined, person_id)
      .then((response) => {
        set((state) => {
          const {nodes, ...restActiveTreeData} = state.trees[treeId]

          const indexOfNode = nodes.findIndex(({id}) => node_id === id)
          // console.log('### indexOfNode', indexOfNode)

          if (indexOfNode !== -1) {
            nodes[indexOfNode] = {
              ...nodes[indexOfNode],
              person_id,
            }

            console.log('### restActiveTreeData', restActiveTreeData)
            return {
              ...state,
              trees: {
                ...state.trees,
                [treeId]: {...restActiveTreeData, nodes: [...nodes]},
              },
            }
          }

          return {...state}
        })
      })
      .catch((error) => console.log('### updateNode.error', error))
  },
  deleteNode: (treeId, nodeId) => {
    set((state) => ({...state, busy: true}))

    deleteNode(treeId, nodeId)
      .then((response) => {
        set((state) => {
          const {nodes, ...restActiveTreeData} = state.trees[treeId]

          return {
            ...state,
            trees: {
              ...state.trees,
              [treeId]: {...restActiveTreeData, nodes: [...nodes.filter(({id}) => nodeId !== id)]},
            },
          }
        })
      })
      .catch((error) => console.log('### ### deleteNode.error', error))
      .finally(() => set((state) => ({...state, busy: false})))
  },
  addEmptyRootParent: (treeId, nodeId) => {
    set((state) => ({...state, busy: true}))
    // request to add empty node
    createNode(treeId)
      .then((response) => {
        const parentId: number = response.data.id

        // request to update current node with adding parent_id with id of new node
        return updateNode(treeId, nodeId, parentId).then((response) => {
          // @ts-ignore
          set((state) => {
            const {nodes, ...restActiveTreeData} = state.trees[treeId]

            // add new parent node to tree
            const nodesWithNewParent = [...nodes, {id: parentId}]

            // bind current node to parent

            const indexOfNode = nodesWithNewParent.findIndex(({id}) => nodeId === id)

            nodesWithNewParent[indexOfNode] = {
              ...nodes[indexOfNode],
              // @ts-ignore
              parent_id: parentId,
            }

            return {
              ...state,
              trees: {
                ...state.trees,
                [treeId]: {...restActiveTreeData, nodes: nodesWithNewParent},
              },
            }
          })
        })
      })
      .catch((error) => console.log('### createNode.error', error))
      .finally(() => set((state) => ({...state, busy: false})))
  },
  deleteRootNode: (treeId, nodeId) => {
    set((state) => ({...state, busy: true}))

    deleteNode(treeId, nodeId)
      .then((response) => {
        set((state) => {
          const {nodes, ...restActiveTreeData} = state.trees[treeId]

          return {
            ...state,
            trees: {
              ...state.trees,
              [treeId]: {...restActiveTreeData, nodes: [...nodes.filter(({id}) => nodeId !== id)]},
            },
          }
        })
      })
      .catch((error) => console.log('### ### deleteNode.error', error))
      .finally(() => set((state) => ({...state, busy: false})))
  },
  setHeirNodeId: (treeId: TreeModel['id'], nodeId: Node['id']) => {
    const {trees, updateTree} = get()
    const tree = trees[treeId]
    if (!tree) return Promise.reject()

    return updateTree(treeId, {...tree, params: {...tree.params, heirNodeId: nodeId}})
  },
  findPersonIdByNodeId: (treeId, nodeId) => {
    const trees = get().trees
    const tree = trees[treeId]

    if (!tree) return null

    const node = tree.nodes.find((n) => n.id === nodeId)

    return node?.person_id || null
  },
}))
