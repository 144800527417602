import React from 'react'
import styled from 'styled-components'

import {useSimDrawerStore} from '@_metronic/partials/layout/sim-drawer/SimDrawer'

import useTreeStyles from '@hooks/useTreeStyles'
import useFirstLetters from '@components/organisms/Tree/hooks/useFirstLetters'
import useHighlightParents from '@components/organisms/Tree/hooks/useHighlightParents'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

import {TreeModel} from '@api/models'
import {PetModel} from '@components/organisms/Tree/types'

const PADDING = 2

type PetProps = {size: number; pet: PetModel; treeId: TreeModel['id']; editMode: boolean}
const Pet = ({size, pet, treeId, editMode}: PetProps) => {
  const {show: showSimDrawer} = useSimDrawerStore()
  const handleNodePress = (e) => {
    if (pet.id && !editMode) {
      showSimDrawer(pet.id)
    }
  }

  const {
    petBorderColor,
    petBorderWidth = 2,
    petBorderRadius,
    petBorderType,
    avatarShadowColor,
    avatarShadowDisplaying,
  } = useTreeStyles(treeId)
  const petBorderWidthParsed = parseInt(`${petBorderWidth}`)
  const petBorderRadiusParsed = parseInt(`${petBorderRadius}`)

  const firstLetters = useFirstLetters(pet.name)
  const clickable = !editMode && typeof pet.id !== 'undefined'
  const highlight = useHighlightParents(pet, false, editMode)

  const avatarUrl = useHostBasedUrl(pet.avatar)

  return (
    <Wrapper
      id='kt_sim_toggle'
      $size={size}
      $clickable={clickable}
      $highlight={highlight}
      onClick={handleNodePress}
    >
      <Avatar
        $size={size}
        $avatarBorderColor={petBorderColor}
        $avatarBorderWidth={petBorderWidthParsed}
        $avatarBorderRadius={petBorderRadiusParsed}
        $avatarBorderType={petBorderType as string}
        $avatarShadowColor={avatarShadowColor}
        $avatarShadowDisplaying={avatarShadowDisplaying}
      >
        {avatarUrl ? (
          <AvatarBackground src={avatarUrl} />
        ) : firstLetters ? (
          <Initials $size={size}>{firstLetters}</Initials>
        ) : (
          <AvatarBackground src={require('@assets/images/pet-avatar.jpeg')} />
        )}
      </Avatar>
    </Wrapper>
  )
}

export default React.memo(Pet)

const Wrapper = styled.div<{
  $size: number
  $clickable: boolean
  $highlight: boolean
  $debugColor?: string
}>`
  display: flex;

  justify-content: flex-start;
  align-items: center;

  height: ${({$size}) => $size}px;
  width: ${({$size}) => $size}px;

  margin-left: -${({$size}) => $size / 2 - PADDING}px;
  padding: ${PADDING}px;

  cursor: ${({$clickable}) => ($clickable ? 'pointer' : 'initial')};
  user-select: none;

  position: relative;

  opacity: ${({$highlight}) => ($highlight ? 1 : 0.4)};
  transition: opacity 300ms;

  background-color: #${({$debugColor}) => $debugColor};
`

const Avatar = styled.div<{
  $size: number
  $avatarBorderColor?: string | number
  $avatarBorderWidth: number
  $avatarBorderRadius: number
  $avatarBorderType: string
  $avatarShadowDisplaying?: string | number
  $avatarShadowColor?: string | number
}>`
  width: ${({$size}) => $size - PADDING * 2}px;
  height: ${({$size}) => $size - PADDING * 2}px;

  border: ${({$avatarBorderType}) => $avatarBorderType}
    ${({$avatarBorderWidth}) => $avatarBorderWidth}px
    ${({$avatarBorderColor}) => $avatarBorderColor};
  border-radius: ${({$avatarBorderRadius}) => $avatarBorderRadius}%;

  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);

  box-shadow: ${({$avatarShadowDisplaying, $avatarShadowColor}) =>
    $avatarShadowDisplaying === 'true'
      ? `2px 2px 4px 0 ${$avatarShadowColor || 'rgba(0, 0, 0, 0.2)'}`
      : 'none'};

  box-sizing: border-box;

  overflow: hidden;
`

const AvatarBackground = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;

  vertical-align: unset;
`

const Initials = styled.div<{$size: number}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  width: 100%;
  height: 100%;

  font-size: ${({$size}) => $size * 0.3}px;
  color: #fff;
  font-weight: 500;
`
