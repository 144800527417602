import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import styled from 'styled-components'

import {useAuth} from '@modules/auth'
import {getBackgroundItems, postBackgroundOrder} from '@api/adminRequests'

import ProButton from '@components/atoms/ProButton'
import Background from '@components/molecules/StylingBackgrounds/components/PackBackgrounds/Background'
import {BackgroundPackType} from '@components/molecules/StylingBackgrounds/types'

const checkIsPaidPack = (pack: BackgroundPackType) =>
  ['donate', 'fix-price'].includes(pack.payment_type)

type PackBackgroundsProps = {
  pack: BackgroundPackType
  value?: number
  onChange: (id: number) => void
  onResetPackSelection: () => void
}
const PackBackgrounds = ({pack, value, onChange, onResetPackSelection}: PackBackgroundsProps) => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  const paidPack = checkIsPaidPack(pack)
  const [initialized, setInitialized] = useState(!paidPack)
  const [checkoutLink, setCheckoutLink] = useState()
  const [purchased, setPurchased] = useState(!paidPack || pack.purchased)

  const disableBackgrounds = useMemo(() => {
    if (pack.access === 'subscribers' && !currentUser?.is_premium) return true

    return paidPack ? !purchased : false
  }, [pack, paidPack, purchased, currentUser])

  useEffect(() => {
    if (pack && !pack.purchased && paidPack && currentUser) {
      postBackgroundOrder({background: pack.id, userId: currentUser.id})
        .then(({id, checkout, state}) => {
          if (state === 1) {
            setCheckoutLink(checkout)
          } else {
            setPurchased(true)
          }
        })
        .finally(() => setInitialized(true))
    }
  }, [])

  const [backgroundsPack, setBackgroundsPack] = useState<BackgroundPackType>()
  const [loadingBackgroundsPack, setLoadingBackgroundsPack] = useState(false)

  useEffect(() => {
    if (pack) {
      setLoadingBackgroundsPack(true)
      getBackgroundItems(pack.id)
        .then((response) => {
          setBackgroundsPack(response)
        })
        .finally(() => setLoadingBackgroundsPack(false))
    }
  }, [pack])

  if (!backgroundsPack || loadingBackgroundsPack) {
    return (
      <LoaderWrapper>
        <span className='indicator-progress d-flex justify-content-center align-items-center'>
          {intl.formatMessage({id: 'ACTIONS.WAIT'})}
          <span className='spinner-border spinner-border-sm align-middle ms-2' />
        </span>
      </LoaderWrapper>
    )
  }

  return (
    <>
      <div className='card-header mb-6 px-0'>
        <h4 className='card-title fw-bold text-gray-900'>
          <div className='page-item'>
            <BackButton className='page-link' onClick={onResetPackSelection}>
              <i className='previous me-2' />
              {intl.formatMessage({id: 'ACTIONS.BACK'})}
            </BackButton>
          </div>
        </h4>
        {checkoutLink && initialized ? (
          <div className='card-toolbar'>
            <a href={checkoutLink} className='btn btn-sm btn-light'>
              {pack.payment_type === 'donate'
                ? intl.formatMessage({id: 'ACTIONS.DONATE'})
                : `${intl.formatMessage({id: 'ACTIONS.PAY'})} ${pack.price ? pack.price : ''}`}
            </a>
          </div>
        ) : null}

        {!checkoutLink && initialized && pack.access === 'subscribers' ? (
          <div className='card-toolbar '>
            <ProButton big />
          </div>
        ) : null}
      </div>
      <div className='row gy-3 mb-5'>
        {backgroundsPack?.items.map((background) => (
          <Background
            key={background.id}
            background={background}
            disabled={disableBackgrounds}
            selected={value === background.resource?.id}
            onClick={() => !disableBackgrounds && onChange(background.resource?.id)}
          />
        ))}
      </div>
    </>
  )
}

export default React.memo(PackBackgrounds)

const LoaderWrapper = styled.div`
  margin-top: 50px;
`

const BackButton = styled.span`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
