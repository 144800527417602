import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import clsx from 'clsx'

import {TreeModel} from '@api/models'

import useOnScreen from '@hooks/useOnScreen'
import {useTreesStore} from '@store/trees'
import {DEFAULT_TREE_STYLES} from '@hooks/useTreeStyles'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'

import StylingFields from '@components/molecules/StylingFields'
import StylingBackgrounds from '@components/molecules/StylingBackgrounds'

import {KTIcon} from '@_metronic/helpers'

import {TreeBackgroundColorsEnum} from '@constants/treeBackgroundColors'

enum Tabs {
  background = 'background',
  styles = 'styles',
}

const DEFAULT_TAB = Tabs.background

const MySwal = withReactContent(Swal)

type TreeCustomizationDrawerProps = {}
const TreeCustomizationDrawer = ({}: TreeCustomizationDrawerProps) => {
  const intl = useIntl()
  const [tab, setTab] = useState(DEFAULT_TAB)

  const ref = useRef(null)
  const visible = useOnScreen(ref)

  useEffect(() => {
    if (!visible) {
      setTab(DEFAULT_TAB)
    }
  }, [visible])

  const closeButtonRef = useRef<HTMLButtonElement>(null)

  const [selectedBack, setSelectedBack] = useState<TreeBackgroundColorsEnum>()
  const [persistFields, setPersistFields] = useState<any>({})

  const [newTreeValues, setNewTreeValues] = useState<{
    background_id: number
    params: TreeModel['params']
  }>()

  const {updateTree, activeTreeId, trees} = useTreesStore()
  const [tree, setTree] = useState<TreeModel | null>(null)

  useEffect(() => {
    if (typeof activeTreeId !== 'undefined' && trees[activeTreeId]) {
      setTree(trees[activeTreeId])
    }
  }, [activeTreeId, trees])

  useEffect(() => {
    if (tree) {
      if (tree.background?.id) {
        setSelectedBack(tree.background.id)
      }
      setPersistFields(tree.params ? tree.params.persist : [])
    }
  }, [tree])

  useEffect(() => {
    const persistValues = Object.keys(DEFAULT_TREE_STYLES).reduce((res, name) => {
      res[name] = persistFields?.[name] || DEFAULT_TREE_STYLES[name]

      return res
    }, {})

    const newValues = {
      background_id: selectedBack,
      params: {...tree?.params, persist: persistValues},
    }

    // @ts-ignore
    setNewTreeValues(newValues)
  }, [selectedBack, persistFields])

  const {show: showSubscriptionModal} = useSubscriptionModalStore()

  const editTreeEnd = (resetToDefault?: boolean) => {
    console.log('### editTreeEnd.newTreeValues', newTreeValues)
    const data = resetToDefault
      ? {params: {...tree?.params, persist: DEFAULT_TREE_STYLES}}
      : {...newTreeValues}

    console.log('### data', data)
    // @ts-ignore
    updateTree(activeTreeId!, data)
      .then(() => {
        closeButtonRef.current?.click()
        setSelectedBack(undefined)
        MySwal.fire({
          position: 'center',
          icon: 'success',
          title: intl.formatMessage({id: 'MSG.TREE.EDITED'}),
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false,
        })
      })
      .catch((error) => {
        console.log('### error', error)
        if (error.response.status === 402) {
          showSubscriptionModal()
        }
      })
  }

  const handleResetCustomTreeStylesClick = () => {
    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'TREE.STYLING.APPLY_DEFAULT_STYLES'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'CONFIRM'}),
    }).then((result) => {
      if (result.isConfirmed) {
        editTreeEnd(true)
      }
    })
  }

  return (
    <div
      id='kt_tree_customization'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='addsim'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_tree_customization_toggle'
      data-kt-drawer-close='#kt_tree_customization_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header' id='kt_help_header'>
          <h5 className='card-title fw-bold'>{intl.formatMessage({id: 'TREE.STYLING.TITLE'})}</h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_tree_customization_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        <div className='card-header'>
          <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0'>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {active: tab === Tabs.background})}
                onClick={() => setTab(Tabs.background)}
              >
                {intl.formatMessage({id: 'TREE.ADD.BACK'})}
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {active: tab === Tabs.styles})}
                onClick={() => setTab(Tabs.styles)}
              >
                {intl.formatMessage({id: 'TREE.ADD.STYLES'})}
              </a>
            </li>
          </ul>
        </div>

        {/* begin::Body */}

        <div
          ref={ref}
          id='kt_tree_customization_body'
          className='card-body hover-scroll-overlay-y h-400px pt-0'
        >
          <StylingBackgrounds
            focused={tab === Tabs.background}
            treeId={tree?.id}
            value={selectedBack}
            visible={visible}
            onChange={setSelectedBack}
          />

          <StylingFields
            focused={tab === Tabs.styles}
            treeId={tree?.id}
            values={persistFields}
            onChange={setPersistFields}
            onReset={handleResetCustomTreeStylesClick}
          />
        </div>
        {/* end::Body */}

        {/* begin::Footer */}
        <div className='card-footer py-5 text-center' id='kt_activities_footer'>
          <div className='d-flex justify-content-end'>
            <button
              id='kt_tree_customization_close'
              ref={closeButtonRef}
              className='btn btn-secondary d-flex justify-content-end me-3'
            >
              {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
            </button>
            <button
              onClick={() => editTreeEnd()}
              className='btn btn-success d-flex justify-content-end'
            >
              {intl.formatMessage({id: 'TREE.ADD.SAVE'})}
            </button>
          </div>
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export default React.memo(TreeCustomizationDrawer)
