import React, {useEffect, useMemo, useState} from 'react'
import {TreeModel} from '@api/models'
import {useAuth} from '@modules/auth'

import useFetchUserBackgrounds from '@components/molecules/StylingBackgrounds/hooks/useFetchUserBackgrounds'

import BackgroundPacks from './components/BackgroundPacks'
import PackBackgrounds from '@components/molecules/StylingBackgrounds/components/PackBackgrounds'

import {TreeBackgroundColorsEnum} from '@constants/treeBackgroundColors'
import {BackgroundPackType} from '@components/molecules/StylingBackgrounds/types'
import {useLang} from '@_metronic/i18n/Metronici18n'

type StylingBackgroundsProps = {
  focused: boolean
  treeId?: TreeModel['id']
  value?: TreeBackgroundColorsEnum
  visible: boolean
  onChange: (value?: TreeBackgroundColorsEnum) => void
}
const StylingBackgrounds = ({
  focused,
  treeId,
  value,
  visible,
  onChange,
}: StylingBackgroundsProps) => {
  const language = useLang()
  const {currentUser} = useAuth()

  const [openedPack, setOpenedPack] = useState<BackgroundPackType>()

  const {backgrounds: userBackgrounds, loading: loadingBackgrounds} = useFetchUserBackgrounds(
    currentUser?.id,
    true
  )

  const backgroundsFilteredByLanguage = useMemo(() => {
    return userBackgrounds?.filter(
      ({region}) => region === 'any' || (language === 'ru' ? region === 'ru' : region !== 'ru')
    )
  }, [language, userBackgrounds])

  useEffect(() => {
    if (!visible) {
      setOpenedPack(undefined)
    }
  }, [visible])

  if (!focused) return null

  if (loadingBackgrounds) {
    return (
      <span className='indicator-progress' style={{display: 'block'}}>
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
    )
  }

  if (openedPack)
    return (
      <PackBackgrounds
        pack={openedPack}
        value={value}
        onChange={onChange}
        onResetPackSelection={() => setOpenedPack(undefined)}
      />
    )

  return (
    <BackgroundPacks
      treeId={treeId}
      packs={backgroundsFilteredByLanguage}
      onChange={onChange}
      onPackSelect={setOpenedPack}
    />
  )
}

export default React.memo(StylingBackgrounds)
