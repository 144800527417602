export function registerSW() {
  if (process.env.NODE_ENV !== 'development' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js').then((registration) => {
      registration.onupdatefound = () => {
        const newWorker = registration.installing
        if (newWorker) {
          newWorker.onstatechange = () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              window.location.reload()
            }
          }
        }
      }
    })
  }
}
