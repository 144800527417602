import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useIntl} from 'react-intl'

import {TreeModel} from '@api/models'

import useSessionTs from '@hooks/useSessionTs'
import useCalculateTreeLevels from '@components/organisms/Tree/hooks/useCalculateTreeLevels'

import {useViewTreeDrawerStore} from '@_metronic/partials/layout/viewtree-drawer/ViewTreeDrawer'

type TreeInfoBlockProps = {treeId: TreeModel['id']; tree: TreeModel; editMode: boolean}
const TreeInfoBlock = ({tree, editMode}: TreeInfoBlockProps) => {
  const intl = useIntl()
  const {ts} = useSessionTs()
  const avatar = useMemo(() => {
    if (tree.avatar?.url) {
      return <AvatarBackground src={`${tree.avatar.url}?ts=${ts}`} />
    }

    return <AvatarBackground src='https://my-engine.ru/modules/users/avatar.png' />
  }, [tree.avatar, ts])

  const treeLevelsCount = useCalculateTreeLevels(tree)

  const {show} = useViewTreeDrawerStore()
  const handleOpenTreeCard = () => {
    show(tree.id)
  }

  return (
    <Wrapper onClick={handleOpenTreeCard}>
      <AvatarWrapper>{avatar}</AvatarWrapper>
      <NameStatus>
        <Name>{tree.name}</Name>
        <State>
          {intl.formatMessage({id: 'TREE.GENERATIONS'})}: {treeLevelsCount}
        </State>
      </NameStatus>
    </Wrapper>
  )
}

export default React.memo(TreeInfoBlock)

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.3);

  position: absolute;
  top: 20px;
  left: 20px;
  margin-rigth: 20px;

  padding: 10px;
  border-radius: 12px;

  border: 1px solid rgba(255, 255, 255, 0.4);

  display: flex;

  height: 90px;

  cursor: pointer;
  max-width: calc(100% - 40px);
  box-sizing: border-box;
  overflow: hidden;
`

const AvatarWrapper = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid white;

  overflow: hidden;
  flex-shrink: 0;
`

const AvatarBackground = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  flex: 1;
`

const NameStatus = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  justify-content: center;
  align-items: flex-start;

  margin-left: 20px;
  max-width: calc(100% - 90px);
`

const Name = styled.div`
  color: black;
  font-size: 16px;
  font-weight: 600;

  width: 100%;
  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;
`

const State = styled.span`
  color: black;
  font-size: 14px;
  font-weight: 400;
`
