import React, {useRef} from 'react'
import styled from 'styled-components'
import imageCompression from 'browser-image-compression'
import {useIntl} from 'react-intl'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

import {useTreesStore} from '@store/trees'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'

import {uploadImage} from '@api/requests'
import {TreeModel} from '@api/models'
import {useAuth} from '@modules/auth'

import BackgroundPack from './BackgroundPack'

import {TreeBackgroundColorsEnum} from '@constants/treeBackgroundColors'
import {BackgroundPackType} from '@components/molecules/StylingBackgrounds/types'

const MySwal = withReactContent(Swal)

type BackgroundPacksProps = {
  treeId?: TreeModel['id']
  packs: BackgroundPackType[]
  onChange: (value?: TreeBackgroundColorsEnum) => void
  onPackSelect: (pack: BackgroundPackType) => void
}
const BackgroundPacks = ({treeId, packs, onChange, onPackSelect}: BackgroundPacksProps) => {
  const intl = useIntl()
  const {updateTree} = useTreesStore()
  const imageRef = useRef<HTMLInputElement>(null)
  const addBackgroundRef = useRef(null)

  const {currentUser} = useAuth()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()
  const clickToInput = (event) => {
    if (!currentUser?.is_premium) {
      showSubscriptionModal()
      event.preventDefault()
      event.stopPropagation()
      return
    }

    imageRef.current?.click()
  }

  const uploadCustomBackground = (event) => {
    let imageFile = event.target.files[0]

    event.target.value = ''

    imageCompression(imageFile, {maxSizeMB: 1.4, maxWidthOrHeight: 1500, useWebWorker: true})
      .then(function (compressedFile) {
        let reader = new FileReader()
        reader.onload = function (e) {
          if (e && e.target && e.target.result) {
            uploadImage(compressedFile, 2).then((response) => {
              updateTree(treeId!, {background_id: response.data.id}).then(() => {
                onChange(undefined)
                MySwal.fire({
                  position: 'center',
                  icon: 'success',
                  title: intl.formatMessage({id: 'BACK.DONE'}),
                  showConfirmButton: false,
                  timer: 1500,
                  heightAuto: false,
                })
              })
            })
          }
        }
        reader.readAsDataURL(compressedFile)
      })
      .catch(function (error) {
        console.log(error.message) // output: I just want to stop
      })
  }

  return (
    <>
      <div className='card-header mb-6 px-0'>
        <h4 className='card-title fw-bold text-gray-900'>
          {intl.formatMessage({id: 'TREE.ADD.BACK'})}
        </h4>

        <div className='card-toolbar'>
          <OverlayTrigger
            placement='bottom'
            delay={{show: 50, hide: 400}}
            container={addBackgroundRef.current}
            overlay={(props) => {
              // console.log('### props', props)
              return (
                <Tooltip {...props}>{intl.formatMessage({id: 'TREE.ADD.BACK.LOAD.HINT'})}</Tooltip>
              )
            }}
            popperConfig={{
              modifiers: [
                {
                  name: 'offset',
                  enabled: true,
                  options: {
                    offset: [0, 10],
                  },
                },
              ],
            }}
          >
            <span
              className='btn btn-sm btn-light cursor-pointer'
              onClick={clickToInput}
              ref={addBackgroundRef}
            >
              {intl.formatMessage({id: 'TREE.ADD.BACK.LOAD'})}
            </span>
          </OverlayTrigger>
          <BackgroundInput
            ref={imageRef}
            type='file'
            name='avatar'
            accept='.png, .jpg, .jpeg'
            onChange={uploadCustomBackground}
          />
        </div>
      </div>
      <div className='row gy-3 mb-5'>
        {packs.map((pack) => (
          <div key={pack.id} className='col-md-6'>
            <BackgroundPack pack={pack} onSelect={onPackSelect} />
          </div>
        ))}
      </div>
    </>
  )
}

export default React.memo(BackgroundPacks)

const BackgroundInput = styled.input`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  width: 110px;
`
