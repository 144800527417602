import {useEffect, useState, useCallback} from 'react'

interface BeforeInstallPromptEvent extends Event {
  prompt: () => void
  userChoice: Promise<{outcome: 'accepted' | 'dismissed'}>
}

export function usePWAInstall() {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null)
  const [canInstall, setCanInstall] = useState(false)
  const [isInstalled, setIsInstalled] = useState(false)

  useEffect(() => {
    const checkIsInstalled = () =>
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any)?.standalone

    setIsInstalled(checkIsInstalled())

    const savedCanInstall = localStorage.getItem('canInstall') === 'true'
    if (savedCanInstall) setCanInstall(true)

    const handler = (event: BeforeInstallPromptEvent) => {
      event.preventDefault()
      setDeferredPrompt(event)
      setCanInstall(true)
      localStorage.setItem('canInstall', 'true')
    }

    window.addEventListener('beforeinstallprompt', handler as EventListener)

    return () => {
      window.removeEventListener('beforeinstallprompt', handler as EventListener)
    }
  }, [])

  const installPWA = useCallback(async () => {
    if (!deferredPrompt) return

    await deferredPrompt.prompt()

    const {outcome} = await deferredPrompt.userChoice
    if (outcome === 'accepted') {
      setIsInstalled(true)
      setCanInstall(false)
      localStorage.removeItem('canInstall')
    }

    setDeferredPrompt(null)
  }, [deferredPrompt])

  return {installPWA, isInstalled, canInstall}
}
