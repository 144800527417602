import {useEffect, useRef} from 'react'

const useBlockContextMenu = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const listener = (event) => {
      if (window.location.hostname !== 'localhost') {
        event.preventDefault()
        event.stopPropagation() // not necessary in my case, could leave in case stopImmediateProp isn't available?
        event.stopImmediatePropagation()
      }

      return false
    }
    ref.current?.addEventListener('contextmenu', listener, false)

    return () => {
      ref.current?.removeEventListener('contextmenu', listener, false)
    }
  }, [])

  return ref
}

export default useBlockContextMenu
