import React, {useRef} from 'react'
import {useIntl} from 'react-intl'
import styled from 'styled-components'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

import {usePhotosStore} from '@app/store/photos'
import {useAuth} from '@app/modules/auth'

import useSessionTs from '@hooks/useSessionTs'

import {PersonsModalMode, usePersonsModalStore} from '@components/molecules/PersonsModal'

import {KTIcon} from '@_metronic/helpers'
import {FIELD_IMAGE_TARGET_SIZES} from '@app/constants/imageUploading'

const swapElements = (array, index1, index2) => {
  const arrayClone = [...array]
  arrayClone[index1] = arrayClone.splice(index2, 1, arrayClone[index1])[0]

  return arrayClone
}

type CustomFieldsProps = {
  title?: string
  allowCopy?: boolean
  allowAddAction?: boolean
  allowDeleteAction?: boolean
  values: [string, string, string | null][]
  onChange: (values: [string, string, string | null][]) => void
}
const CustomFields = ({
  title,
  allowCopy = false,
  allowAddAction = false,
  allowDeleteAction = true,
  values = [],
  onChange,
}: CustomFieldsProps) => {
  console.log('### CustomFields.values', values)
  const intl = useIntl()
  const {ts} = useSessionTs()
  const {currentUser} = useAuth()
  const {show: showPersonSelectModal} = usePersonsModalStore()
  const {selectPhoto} = usePhotosStore()
  const premiumMessageRef = useRef(null)

  const addField = () => {
    let fields
    if (!values) fields = []
    else fields = [...values]
    fields.push(['', '', null])
    onChange([...fields])
  }

  const changeCustomFields = (e) => {
    let key = parseInt(e.currentTarget.dataset.key)
    let fieldId = parseInt(e.currentTarget.dataset.id)
    // console.log('### changeCustomFields', key, fieldId)
    let fields

    if (!values) fields = []
    else fields = [...values]
    fields[fieldId][key] = e.currentTarget.value

    onChange([...fields])
  }

  const removeField = (id) => {
    let fields
    if (!values) fields = []
    else fields = [...values]
    fields.splice(id, 1)

    onChange([...fields])
  }

  const onMove = (index, newIndex) => {
    const newOrder = swapElements(values, index, newIndex).map(([name, value, image, oldTs]) => [
      name,
      value,
      image,
      ts,
    ])
    console.log('### newOrder', newOrder)
    // @ts-ignore
    onChange(newOrder)
  }

  const handleCopyPress = () => {
    showPersonSelectModal(
      {
        personSelect: (selectedPerson) => {
          const params = selectedPerson?.params
          if (params) {
            let fields
            if (!values) {
              fields = []
            } else {
              fields = [...values]
            }

            ;(params.fields || []).forEach(([name, value, image]) =>
              fields.push([name, value, image])
            )

            onChange([...fields])
          }
        },
      },
      PersonsModalMode.personForNodeSelect
    )
  }

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, fieldId: number): void => {
    if (!currentUser?.is_premium) return

    const file = event.target.files?.[0]
    if (file) {
      selectPhoto(
        file,
        (croppedFile) => {
          console.log('### croppedFile', croppedFile)
          const reader = new FileReader()
          reader.onload = (e) => {
            const fields = [...values]
            fields[fieldId][2] = e.target?.result as string
            onChange(fields)
          }
          reader.readAsDataURL(croppedFile)
        },
        1,
        FIELD_IMAGE_TARGET_SIZES,
        false,
        true
      )
    }
  }

  const handleImageRemove = (fieldId: number): void => {
    const fields = [...values]
    fields[fieldId][2] = null
    onChange(fields)
  }

  return (
    <div className='mt-10 mb-10'>
      <div className='d-flex justify-content-between align-items-center'>
        <label className='form-label'>{title ?? intl.formatMessage({id: 'ADD.FIELD.TITLE'})}</label>
      </div>

      {values.map(([name, value, image], index) => {
        const tooltipId = `premium-tooltip-${index}`

        return (
          <div key={index} className='input-group mb-5 !z-0' style={{zIndex: 0}}>
            <input
              className='form-control'
              type='text'
              value={name}
              placeholder={intl.formatMessage({id: 'ADD.FIELD.NAME'})}
              data-id={index}
              data-key={0}
              onChange={changeCustomFields}
            />
            <input
              type='text'
              className='form-control'
              value={value}
              placeholder={intl.formatMessage({id: 'ADD.FIELD.TEXT'})}
              data-id={index}
              data-key={1}
              onChange={changeCustomFields}
            />
            <ImageWrapper>
              {image && (
                <div className='me-3'>
                  <ImagePreview src={image} alt='Preview' />
                </div>
              )}
              {image ? (
                <button
                  className='btn btn-icon btn-active-color-primary w-25px h-25px'
                  onClick={(e) => {
                    e.stopPropagation()
                    handleImageRemove(index)
                  }}
                >
                  <i className='ki-duotone ki-cross fs-3'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </button>
              ) : (
                <>
                  {!currentUser?.is_premium ? (
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 50, hide: 400}}
                      overlay={
                        <Tooltip id={tooltipId}>
                          {intl.formatMessage({id: 'SIM.TOOLTIP.CUSTOM_FIELD.PRO'})}
                        </Tooltip>
                      }
                      popperConfig={{
                        modifiers: [
                          {
                            name: 'offset',
                            enabled: true,
                            options: {
                              offset: [0, 10],
                            },
                          },
                        ],
                      }}
                    >
                      <label
                        className='btn btn-icon btn-active-color-primary w-25px h-25px p-1'
                        ref={premiumMessageRef}
                      >
                        <i className='ki-duotone ki-picture fs-3'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        <input
                          type='file'
                          accept='image/*'
                          style={{display: 'none'}}
                          onChange={(e) => handleImageUpload(e, index)}
                          disabled={!currentUser?.is_premium}
                        />
                      </label>
                    </OverlayTrigger>
                  ) : (
                    <label className='btn btn-icon btn-active-color-primary w-25px h-25px p-1'>
                      <i className='ki-duotone ki-picture fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                      <input
                        type='file'
                        accept='image/*'
                        style={{display: 'none'}}
                        onChange={(e) => handleImageUpload(e, index)}
                        disabled={!currentUser?.is_premium}
                      />
                    </label>
                  )}
                </>
              )}
            </ImageWrapper>
            <Handle
              className='btn btn-secondary btn-icon w-auto px-3'
              disabled={index === 0}
              onClick={() => onMove(index, index - 1)}
            >
              <i className='ki-duotone ki-arrow-up'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </Handle>
            <Handle
              className='btn btn-secondary btn-icon w-auto px-3 '
              disabled={index + 1 === values?.length}
              onClick={() => onMove(index, index + 1)}
            >
              <i className='ki-duotone ki-arrow-down'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </Handle>

            {allowDeleteAction ? (
              <a
                className='btn btn-secondary btn-icon w-auto px-3 z-0'
                onClick={() => removeField(index)}
              >
                <KTIcon iconName='trash' className='fs-3' />
              </a>
            ) : null}
          </div>
        )
      })}

      <a
        className='btn btn-secondary d-flex justify-content-center align-items-center'
        onClick={addField}
      >
        <KTIcon iconName='plus' className='fs-3' />
        {intl.formatMessage({id: 'PERSON.ADD'})}
      </a>

      {allowCopy ? (
        <a
          className='btn btn-secondary d-flex justify-content-center align-items-center mt-5'
          onClick={handleCopyPress}
        >
          <KTIcon iconName='clipboard' className='fs-3 me-2' />
          {intl.formatMessage({id: 'ACTIONS.COPY_ADDITIONAL_FIELDS'})}
        </a>
      ) : null}
    </div>
  )
}

export default React.memo(CustomFields)

const Handle = styled.button`
  height: 40px;
  width: 30px;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--bs-gray-300);
  border-left: none;
  padding: 5px;
`

const ImagePreview = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;
`
