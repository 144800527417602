import React, {useRef} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import styled from 'styled-components'

import PersonInfo from './PersonInfo'

interface TreeNodeSimInfoTooltipProps {
  personId: number
  children: React.ReactNode
}

const TreeNodeSimInfoTooltip: React.FC<TreeNodeSimInfoTooltipProps> = ({personId, children}) => {
  const ref = useRef<HTMLDivElement | null>(null)

  return (
    <OverlayTrigger
      placement='top'
      delay={{show: 50, hide: 400}}
      container={ref.current}
      overlay={(props) => {
        return (
          <PartnerInfoTooltip {...props}>
            <PersonInfo rowsMode personId={personId} showHeader={false} />
          </PartnerInfoTooltip>
        )
      }}
      popperConfig={{
        modifiers: [
          {
            name: 'offset',
            enabled: personId ? true : false,
            options: {
              offset: [0, 10],
            },
          },
        ],
      }}
    >
      <div ref={ref}>{children}</div>
    </OverlayTrigger>
  )
}

export default TreeNodeSimInfoTooltip

const PartnerInfoTooltip = styled(Tooltip)`
  z-index: 100;

  .tooltip-inner {
    font-size: 8px;
    padding: 2px 5px;
    border-radius: 3px;

    max-width: 420px;
  }
`
