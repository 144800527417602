import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useIntl} from 'react-intl'
import {create} from 'zustand'

import useTreeStyles from '@hooks/useTreeStyles'

import ErrorBoundary from '@components/atoms/ErrorBoundary'
import NodeInfo from '@components/organisms/Tree/components/Node/components/NodeInfo'

import Partner from './components/Partner'
import PartnersCarouselControls from './components/PartnersCarouselControls'

import {NODE_AVATAR_SIZE, NODE_HEIGHT} from '@components/organisms/Tree/constants'

const MAX_PARTNERS_TO_DISPLAY = 3
const PARTNERS_AROUND = 1
const PARTNERS_COUNT_WITH_AROUND = MAX_PARTNERS_TO_DISPLAY + PARTNERS_AROUND * 2

const ANIMATION_DURATION = 500

type PartnersCarouselOffset = {
  offsets: {[key: string]: number}
  setOffset: (id: string, offset: number) => void
}
const usePartnersCarouselOffset = create<PartnersCarouselOffset>((set) => ({
  offsets: {},
  setOffset: (id, offset) => {
    // console.log('### setOffset2', id, offset)
    set((state) => {
      console.log({...state, [id]: offset})
      return {...state, offsets: {...state.offsets, [id]: offset}}
    })
  },
}))

export const partnerHeightBasedOnCount = (partnersCount: number, lineWidth?: number) => {
  if (partnersCount === 1) return NODE_AVATAR_SIZE
  if (partnersCount === 2) return NODE_AVATAR_SIZE * 0.9

  return NODE_AVATAR_SIZE * 0.8 - (lineWidth || 0)
}

const Partners = ({style, owner, editMode, treeId, subTreeOwner, readonly}) => {
  // useLayoutEffect(() => {
  //   console.log('### Partners +')
  //
  //   return () => {
  //     console.log('### Partners -')
  //   }
  // }, [])
  const intl = useIntl()
  const partners = useMemo(
    () => owner.partners.sort(({id: idA}, {id: idB}) => idB - idA),
    [owner.partners]
  )

  const partnersCount = partners.length
  const doublePartnersCount = partnersCount * 2

  const withScroll = partnersCount > MAX_PARTNERS_TO_DISPLAY
  const withPadding = partnersCount > 1

  const {lineWidth} = useTreeStyles(treeId)
  const lineWidthParsed = parseInt(`${lineWidth}`)

  const size = useMemo(() => {
    return partnerHeightBasedOnCount(partnersCount, lineWidthParsed)
  }, [partnersCount])

  const mergedStyle = useMemo(() => {
    const maxPartnersCount = Math.min(MAX_PARTNERS_TO_DISPLAY, partnersCount)
    const marginTop = (NODE_HEIGHT - maxPartnersCount * size + (maxPartnersCount - 1) * 0) / 2

    return {
      ...style,
      height: size * maxPartnersCount,
      marginTop: marginTop,
    }
  }, [style, size, partnersCount])

  const partnersToDisplay = useMemo(() => {
    // console.log('### partners')
    // console.log(partners)

    let preparedPartners
    if (withScroll) {
      preparedPartners = [
        ...partners.map((partner) => ({
          ...partner,
          virtualId: partner.id,
        })),
        ...partners.map((partner) => ({
          ...partner,
          virtualId: `${partner.id}_double`,
        })),
      ]
    } else {
      preparedPartners = partners.map((partner) => ({
        ...partner,
        virtualId: partner.id,
      }))
    }

    const sameIdCounter = {}
    preparedPartners = preparedPartners.reverse().map((partner) => {
      let virtualId = partner.virtualId
      // console.log('### virtualId', virtualId, partner.id)
      if (virtualId) {
        if (sameIdCounter[partner.virtualId]) {
          virtualId = `${partner.virtualId}_${sameIdCounter[partner.virtualId]}`
          sameIdCounter[partner.virtualId] = sameIdCounter[partner.virtualId] + 1
        } else {
          sameIdCounter[partner.virtualId] = 1
        }
      }

      return {
        ...partner,
        virtualId,
      }
    })

    return preparedPartners
  }, [withScroll, partners])

  // console.log(partnersToDisplay.map((partner) => partner.virtualId))

  const partnersIds = useMemo(
    () => partnersToDisplay.map((partner) => partner.virtualId || partner.id).join('_'),
    [partnersToDisplay]
  )
  // console.log('### partnersIds', partnersIds)
  const {offsets, setOffset} = usePartnersCarouselOffset()
  // console.log('### offsets', offsets)
  const offset = useMemo(() => {
    if (withScroll) {
      return offsets[partnersIds] || 0
    }

    return 0
  }, [offsets, partnersIds, withScroll])

  // const [offset, setOffset] = useState(0)
  // console.log('### offset', offset)
  const onOffsetChange = (diff: 1 | -1) => {
    // setOffset((currentOffset) => {
    //   const calculatedOffset = currentOffset + diff
    //
    //   if (calculatedOffset < 0) {
    //     return doublePartnersCount + calculatedOffset
    //   } else {
    //     return calculatedOffset % doublePartnersCount
    //   }
    // })
    const calculatedOffset = offset + diff
    const newOffset =
      calculatedOffset < 0
        ? doublePartnersCount + calculatedOffset
        : calculatedOffset % doublePartnersCount
    // console.log('### newOffset', partnersIds, newOffset)
    setOffset(partnersIds, newOffset)
  }

  if (!partnersCount) return null

  // console.log('### partnersToDisplay')
  // console.log(partnersToDisplay)

  return (
    <>
      <Wrapper style={mergedStyle}>
        {withScroll ? (
          <PartnersCarouselControls
            size={size}
            treeId={treeId}
            partnersCount={partnersCount}
            onOffsetChange={onOffsetChange}
          />
        ) : null}
        <PartnersWrapper $maxHeight={withScroll ? size * 3 : undefined}>
          {partnersToDisplay.map((partner, index) => {
            if (withScroll) {
              const offsettedIndex = (index + offset) % doublePartnersCount

              const visible = offsettedIndex > 0 && offsettedIndex <= MAX_PARTNERS_TO_DISPLAY
              const positionOffset =
                offsettedIndex < PARTNERS_COUNT_WITH_AROUND
                  ? size * offsettedIndex
                  : (MAX_PARTNERS_TO_DISPLAY * size) / (offsettedIndex - PARTNERS_COUNT_WITH_AROUND)

              return (
                <ErrorBoundary
                  key={partner.virtualId || partner.id}
                  fallback={
                    <PartnerWithError $size={size}>
                      {intl.formatMessage({id: 'ERROR.HAPPENS'})}
                    </PartnerWithError>
                  }
                >
                  <HiddablePartner $hidden={!visible} $offset={positionOffset - size}>
                    <Partner
                      ownerId={owner.person_id}
                      partner={partner}
                      size={size}
                      treeId={treeId}
                      editMode={editMode}
                      withPadding={withPadding}
                      subTreeOwner={subTreeOwner}
                      readonly={readonly}
                    />
                  </HiddablePartner>
                </ErrorBoundary>
              )
            }

            return (
              <ErrorBoundary
                key={partner.virtualId || partner.id}
                fallback={
                  <PartnerWithError $size={size}>
                    {intl.formatMessage({id: 'ERROR.HAPPENS'})}
                  </PartnerWithError>
                }
              >
                <Partner
                  ownerId={owner.person_id}
                  partner={partner}
                  size={size}
                  treeId={treeId}
                  editMode={editMode}
                  withPadding={withPadding}
                  subTreeOwner={subTreeOwner}
                  readonly={readonly}
                />
              </ErrorBoundary>
            )
          })}
        </PartnersWrapper>
      </Wrapper>
      {partners?.length === 1 ? (
        <NodeInfo style={style} leftOffset={-15} treeId={treeId} person={partners[0]} />
      ) : null}
    </>
  )
}

export default React.memo(Partners)

const Wrapper = styled.div`
  position: absolute;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const PartnersWrapper = styled.div<{$maxHeight?: number}>`
  display: flex;
  flex-direction: column;

  height: ${({$maxHeight}) => ($maxHeight ? `${$maxHeight}px` : 'auto')};
  width: 50px;

  position: relative;
`

const PartnerWithError = styled.div<{$size: number}>`
  width: ${({$size}) => $size}px;
  height: ${({$size}) => $size}px;
  object-fit: cover;
  border: solid 2px crimson;
  border-radius: 50%;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);

  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  color: crimson;

  text-align: center;
`

const HiddablePartner = styled.div<{$hidden: boolean; $offset?: number}>`
  opacity: ${({$hidden}) => ($hidden ? 0 : 1)};
  pointer-events: ${({$hidden}) => ($hidden ? 'none' : 'all')};

  transition: top ${ANIMATION_DURATION}ms, opacity ${ANIMATION_DURATION}ms;

  position: absolute;
  top: ${({$offset}) => $offset}px;
  left: 0;
`
