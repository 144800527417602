import React from 'react'
import {useIntl} from 'react-intl'
import {usePWAInstall} from '@app/hooks/usePWAInstall'
import useIsMobileDevice from '@app/hooks/useIsMobileDevice'

export const InstallPWAButton: React.FC = () => {
  const intl = useIntl()
  const {installPWA, canInstall, isInstalled} = usePWAInstall()
  const isMobile = useIsMobileDevice()

  if (!canInstall || isInstalled) return null

  const ButtonComponent = isMobile ? 'span' : 'button'
  const buttonProps = isMobile
    ? {className: 'menu-title'}
    : {
        className:
          'cursor-pointer badge bg-success text-white pulse pulse-success border-0 shadow-none',
      }

  return (
    <ButtonComponent onClick={installPWA} {...buttonProps}>
      {intl.formatMessage({id: 'INSTALL_PWA_BUTTON'})}
    </ButtonComponent>
  )
}
