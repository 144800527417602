import moment from 'moment'

export const isTimestamp = (value: unknown): boolean => {
  // Проверяем, является ли значение числом или строкой, состоящей из цифр
  if (typeof value === 'number' || (typeof value === 'string' && /^\d+$/.test(value))) {
    // Преобразуем значение в число
    const numericValue = Number(value)
    // Проверяем, является ли значение корректной датой с помощью moment
    return moment(numericValue).isValid()
  }
  return false
}
