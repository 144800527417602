import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import {getPagedSims, getPhotos, uploadImage} from '@api/requests'
import {useAuth} from '@modules/auth'

import useHostBasedUrl from '@hooks/useHostBasedUrl'
import usePersonAvatars from '@hooks/usePersonAvatars'
import useRegetPerson from '@hooks/useRegetPerson'
import usePrevious from '@app/hooks/usePrevious'

import {usePhotosStore} from '@store/photos'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'
import {usePersonsStore} from '@store/persons'

import CustomGallery from '@components/molecules/CustomGallery/CustomGallery'
import RelationsBlock from '@_metronic/partials/layout/sim-drawer/components/RelationsBlock'

import {Fancybox} from '@fancyapps/ui'
import PersonInfo from '@app/components/molecules/PersonInfo/PersonInfo'

import {PersonModel} from '@api/models'

import {GALLERY_IMAGE_TARGET_SIZES} from '@constants/imageUploading'

import {RelationType} from '@app/types'

const getPersonGalleryKey = (personId: number) => `sim_gallery_${personId}`

const HiddenAvatar = ({avatar}) => {
  const url = useHostBasedUrl(avatar?.url)
  return (
    <a
      href={url}
      data-fancybox='avatars-gallery'
      data-fancybox-group='avatars'
      style={{display: 'none'}}
    />
  )
}

const SimDrawerContent = ({personId, readonly = false}) => {
  const intl = useIntl()
  const butOpenEditorRef = useRef<any>()
  const {selectPhoto} = usePhotosStore()

  const {persons, updatePersons, updatedPersons} = usePersonsStore()

  const [loading, setLoading] = useState(false)

  const imageRef = useRef<HTMLInputElement | null>(null)
  const [person, setPerson] = useState<PersonModel | null>(null)
  const [photos, setPhotos] = useState([])

  const regetPerson = useRegetPerson(personId)
  useEffect(() => {
    if (personId) {
      setLoading(true)

      // console.log('### personId', personId)
      getPhotos(getPersonGalleryKey(personId!)).then((r) => {
        // @ts-ignore
        setPhotos(r.data)
      })

      // console.log('### regetPerson', personId)
      regetPerson()
        .then((receivedPerson) => {
          if (receivedPerson) {
            // console.log('### regetPerson.receivedPerson', receivedPerson)

            setPerson(receivedPerson)
          }
        })
        .finally(() => setLoading(false))
    } else {
      setPhotos([])
      setPerson(null)
    }
  }, [personId])

  useEffect(() => {
    if (updatedPersons[personId]) {
      setPerson(updatedPersons[personId])
    }
  }, [updatedPersons[personId]])

  const isPremium = useAuth().currentUser?.is_premium
  const {show: showSubscriptionModal} = useSubscriptionModalStore()
  const clickToInput = (event) => {
    if (!isPremium) {
      showSubscriptionModal()
      event.preventDefault(true)
      event.stopPropagation()
      return
    }

    imageRef.current?.click()
  }
  const callBackForCrop = (file: Blob) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e?.target?.result) {
        uploadImage(file, getPersonGalleryKey(personId!)).then((response) => {
          console.log('### response', response)
          const photosCopy = photos ? [...photos] : []
          // @ts-ignore
          photosCopy.push(response.data)
          setPhotos(photosCopy)
        })
      }
    }
    reader.readAsDataURL(file)
  }

  function handleFiles(event) {
    if (!event.currentTarget.files?.length) return
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, undefined, GALLERY_IMAGE_TARGET_SIZES)

    event.currentTarget.value = ''

    if (butOpenEditorRef?.current) butOpenEditorRef.current.click()
  }

  const avatars = usePersonAvatars(personId)
  const avatarUrl = useHostBasedUrl(person?.avatar?.url)

  const allAvatars = useMemo(() => {
    const avatarList = avatars || []
    return avatarList.filter((a) => a.id !== person?.avatar?.id)
  }, [avatars, person?.avatar])

  const handleImageUpdate = (data) => {
    console.log('### handleImageUpdate', data)

    setPhotos((oldPhotos) => {
      const newPhotos = [...oldPhotos]
      const foundIndex = newPhotos.findIndex(({id}) => id === data.id)
      console.log('### foundIndex', foundIndex)
      if (foundIndex !== -1) {
        // @ts-ignore
        newPhotos[foundIndex] = {...newPhotos[foundIndex], ...data}
      }

      return newPhotos
    })
  }

  const photoUrl = avatarUrl || 'https://my-engine.ru/modules/users/avatar.png'

  // persons
  const prevPerson = usePrevious(person)
  useEffect(() => {
    if (person) {
      // console.log('### person')
      // console.log(person)
      if (!prevPerson || prevPerson.id !== person.id) {
        const petAndPersonIds = [
          ...(person.pets || []),
          ...(person.parents || []),
          ...(person.friends || []),
        ]
        const personIdMap = persons.map(({id}) => id)
        const missedPetsAndParentsIds: PersonModel['id'][] = petAndPersonIds.filter(
          (id) => !personIdMap.includes(id)
        )

        if (missedPetsAndParentsIds.length > 0) {
          getPagedSims({
            ids: missedPetsAndParentsIds,
            fields:
              'id,first_name,last_name,avatar,is_alive,is_subtree,pets,parents,partners,friends',
          }).then((response) => {
            const personPersons = response.data as PersonModel[]

            updatePersons([...persons, ...personPersons], true)
          })
        }
      }
    }
  }, [person])

  useEffect(() => {
    Fancybox.bind('[data-fancybox]', {
      // Your custom options
    })
  }, [])

  // console.log('### SimDrawerContent.person', person)

  return (
    <div className='pt-5' style={{overflowX: 'hidden'}}>
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      <div className='d-flex flex-column text-center mb-9 px-9'>
        <a
          href={avatarUrl}
          data-fancybox='avatars-gallery'
          data-fancybox-group='avatars'
          className={clsx('symbol symbol-150px mb-4', {
            'cursor-pointer': !Boolean(avatarUrl),
            'pe-none': !Boolean(avatarUrl),
          })}
        >
          {/* @ts-ignore*/}
          {avatarUrl ? (
            <>
              <img src={photoUrl} className='' alt='' style={{objectFit: 'cover'}} />
              {allAvatars.map((avatar) => (
                <HiddenAvatar key={avatar.id} avatar={avatar} />
              ))}
            </>
          ) : (
            <img src={photoUrl} alt='' style={{objectFit: 'cover'}} />
          )}

          {loading ? (
            <div className='d-flex flex-fill justify-content-center'>
              <span className='indicator-progress mt-5' style={{display: 'block'}}>
                {intl.formatMessage({id: 'ACTIONS.WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : null}
        </a>

        <div className='text-center'>
          <span className='text-gray-800 fw-bold text-hover-primary fs-1'>
            {person && `${person.first_name} ${person.last_name}`}
          </span>

          <span className='text-muted d-block fw-semibold'>
            {person && intl.formatMessage({id: person.is_alive ? 'PERSON.ALIVE' : 'PERSON.DEAD'})}
          </span>
        </div>
      </div>

      {!readonly || (readonly && photos?.length > 0) ? (
        <div className='card border-0 shadow-none rounded-0 w-100'>
          <div className='card-header p-0 mb-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>
                {intl.formatMessage({id: 'GALLERY.TITLE'})}
              </span>
            </h3>

            <div className='card-toolbar'>
              {!readonly ? (
                <span onClick={clickToInput} className='btn btn-sm btn-light'>
                  {intl.formatMessage({id: 'ADD.PHOTO'})}
                </span>
              ) : null}
              <input
                ref={imageRef}
                style={{position: 'absolute', pointerEvents: 'none', opacity: 0}}
                type='file'
                name='avatar'
                accept='.png, .jpg, .jpeg'
                onChange={handleFiles}
              />
            </div>
          </div>

          {photos?.length > 0 ? (
            <CustomGallery readonly={readonly} photos={photos} onImageUpdate={handleImageUpdate} />
          ) : null}
        </div>
      ) : null}

      {person ? (
        <RelationsBlock
          relationType={RelationType.parent}
          readonly={readonly}
          person={person}
          loading={loading}
        />
      ) : null}
      {person ? (
        <RelationsBlock
          relationType={RelationType.friend}
          readonly={readonly}
          person={person}
          loading={loading}
        />
      ) : null}
      {person && person?.type !== 1 ? (
        <RelationsBlock
          relationType={RelationType.pet}
          readonly={readonly}
          person={person}
          loading={loading}
        />
      ) : null}

      <PersonInfo personId={personId} person={person} loading={loading} showHeader />
    </div>
  )
}

export default React.memo(SimDrawerContent)
